import { SubscriptionType } from '../components/SubscriberUploadDialog/reducers';

export enum SubscriptionFlow {
  ALL = 'ALL',
  SILENT_OPT_IN = 'SILENT',
  DOUBLE_OPT_IN = 'DOUBLE_OPT_IN',
  SINGLE_OPT_IN = 'SINGLE_OPT_IN',
}

export enum SubscriptionChannel {
  ALL = 'ALL',
  EMAIL = 'EMAIL',
  TEXT = 'TEXT',
}

export enum SubscriberType {
  ALL = 'ALL',
  MARKETING = 'MARKETING',
  TRANSACTIONAL = 'TRANSACTIONAL',
}

export enum UploadStatus {
  ALL = 'ALL',
  UPLOADED = 'UPLOADED',
  PROCESS = 'PROCESS',
  COMPLETE = 'COMPLETE',
  ABANDONED = 'ABANDONED',
  INVALID = 'INVALID',
  IN_PROGRESS = 'IN_PROGRESS',
}

export const SubscriptionFlowLabels = {
  ALL: 'All subscriber flows',
  SILENT_OPT_IN: 'Silent Opt In',
  DOUBLE_OPT_IN: 'Double Opt in',
  SINGLE_OPT_IN: 'Legal and Welcome',
};

export const BULK_UPLOADER_SIDEBAR_HEADER = 'Quick tips';

export const TEXT_SUBSCRIPTION_TYPES: SubscriptionType[] = [
  SubscriptionType.SUBSCRIPTION_TYPE_TEXT_MARKETING,
  SubscriptionType.SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL,
];

export enum Steps {
  UPLOAD_TYPE = 'upload-type',
  SUBSCRIPTION_TYPE = 'subscription-type',
  COUNTRY = 'country',
  LANGUAGE = 'language',
  UPLOAD = 'upload',
  MAPPING = 'mapping',
  IMPORT_OPTIONS = 'import-options',
  REVIEW_OPTIONS = 'review-options',
}

export const SETUP_STEPS: Steps[] = [Steps.SUBSCRIPTION_TYPE, Steps.COUNTRY, Steps.LANGUAGE];
